// import { Navigate, Route, Routes } from "react-router-dom"
// import { ToastContainer } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
// import HomeView from "./views/home/View"
// import HomePage from "./views/home/main/HomePage"
// import EquipmentPage from "./views/home/main/EquipmentPage"
// import ContactPage from "./views/home/main/ContactPage"
// import OperatingRulesPage from "./views/home/main/OperatingRulesPage"
// import CustomRouter from "@fastdo-react/router/CustomRouter"
// import history from "@fastdo-react/router/history"
// import AdminView from "./views/admin/AdminView"
// import PrivateRoute from "@fastdo-react/router/PrivateRoute"
// import ReservationPage from "./views/home/main/ReservationPage"
// import CreateReservationPage from "./views/home/reservation/CreateReservationPage"
// import CancelReservationPage from "./views/home/reservation/CancelReservationPage"
// import ConfirmReservationPage from "./views/home/reservation/ConfirmReservationPage"
// import EmailSentReservationPage from "./views/home/reservation/EmailSentReservationPage"
// import HomeAdminPage from "./views/admin/main/HomeAdminPage"
// import ReservationsAdminPage from "./views/admin/main/ReservationsAdminPage"
// import PriceItemsAdminPage from "./views/admin/main/PriceItemsAdminPage"
// import PersonalDataProtectionPage from "./views/home/back/PersonalDataProtectionPage"
// import ReservationDetailPage from "./views/admin/reservations/ReservationDetailPage"
// import agent from "./common/api/agent"
// import { setUser } from "@fastdo-react/services/identityService"
// import ChangePasswordAdminPage from "@fastdo-react/pages/ChangePasswordAdminPage"
// import LoginAdminPage from "@fastdo-react/pages/LoginAdminPage"
// import LogoutPage from "@fastdo-react/pages/LogoutPage"
// import NotFoundView from "@fastdo-react/views/NotFoundView"
// import PricingPage from "./views/home/main/PricingPage"
// import { useStore } from "app/common/stores/store"
// import { observer } from "mobx-react"
// import { useEffect } from "react"
// import TermsOfConfitions from "./views/home/back/TermsOfConfitions"
// export default observer(function App() {
//   const login = async (email: string, password: string) => {
//     try {
//       var response = await agent.SuperAdminUsers.login({
//         email: email,
//         password: password,
//       })

//       if (response.token) {
//         setUser(response.token, "SuperAdmin")
//         return true
//       }
//     } catch (error) {}
//     return false
//   }

//   const changePassword = async (password: string, newPassword: string, newPasswordConfirmation: string) => {
//     try {
//       await agent.SuperAdminUsers.changePassword({
//         password: password,
//         newPassword: newPassword,
//         newPasswordConfirmation: newPasswordConfirmation,
//       })
//       return true
//     } catch (error) {}
//     return false
//   }

//   const { commonStore } = useStore()
//   const { darkTheme, setup } = commonStore

//   useEffect(() => {
//     setup()
//   }, [setup])

//   return (
//     <div data-theme={darkTheme ? "dark" : "light"} style={{ backgroundColor: "var(--primary-background-color)" }}>
//       <CustomRouter history={history}>
//         <ToastContainer style={{ top: "5rem" }} />

//         <Routes>
//           <Route path="/" element={<HomeView />}>
//             <Route index element={<HomePage />} />
//             <Route path="equipment" element={<EquipmentPage />} />
//             <Route path="operatingrules" element={<OperatingRulesPage />} />
//             {/* <Route path="catering" element={<CateringPage />} /> */}
//             <Route path="pricing" element={<PricingPage />} />
//             {/* <Route path="surroundings" element={<SurroundingsPage />} />
//           <Route path="events" element={<EventsPage />} /> */}
//             <Route path="contact" element={<ContactPage />} />

//             <Route path="reservation" element={<ReservationPage />} />
//             <Route path="createreservation" element={<CreateReservationPage />} />
//             <Route path="cancelreservation" element={<CancelReservationPage />} />
//             <Route path="emailsentreservation/:email" element={<EmailSentReservationPage />} />
//             <Route path="confirmreservation/:email/:reservationCode/:token" element={<ConfirmReservationPage />} />

//             <Route path="personaldataprotection" element={<PersonalDataProtectionPage />} />
//             <Route path="termsofconditions" element={<TermsOfConfitions />} />

//             <Route path="login" element={<LoginAdminPage onSubmit={(email, password) => login(email, password)} />} />
//             <Route path="logout" element={<LogoutPage />} />
//           </Route>

//           <Route
//             path="/admin/"
//             element={
//               <PrivateRoute>
//                 <AdminView />
//               </PrivateRoute>
//             }
//           >
//             <Route index element={<HomeAdminPage />} />
//             <Route path="priceitems" element={<PriceItemsAdminPage />} />

//             <Route path="reservations" element={<ReservationsAdminPage />} />
//             <Route path="reservationdetail/:id" element={<ReservationDetailPage />} />

//             <Route
//               path="changepassword"
//               element={
//                 <ChangePasswordAdminPage
//                   onSubmit={(password, newPassword, newPasswordConfirmation) => changePassword(password, newPassword, newPasswordConfirmation)}
//                 />
//               }
//             />
//           </Route>

//           <Route path="/404" element={<NotFoundView logo={process.env.PUBLIC_URL + "/assets/logos/logo.svg"} />} />
//           <Route path="*" element={<Navigate to="/404" />} />
//         </Routes>
//       </CustomRouter>
//     </div>
//   )
// })

import styles from "./App.module.css"
import { routes as homeRoutes } from "./views/home/routes"
import NotFoundView from "views/error/NotFoundView"
import { ToastContainer } from "react-toastify"
import LocalizedNavigate from "components/router/LocalizedNavigate"
import { useAppSelector } from "common/storeHooks"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

export default function App() {
  const themeSlice = useAppSelector((state) => state.theme)

  const router = createBrowserRouter([
    homeRoutes,
    { path: "/404", element: <NotFoundView /> },
    { path: "*", element: <LocalizedNavigate to="/404" /> },
  ])

  return (
    <div data-theme={themeSlice.darkTheme ? "dark" : "light"} className={styles.component}>
      <ToastContainer style={{ top: "5rem" }} />
      <RouterProvider router={router} />
    </div>
  )
}
