import View from "./View"
import PersonalDataProtectionPage from "./back/PersonalDataProtectionPage"
import ContactPage from "./main/ContactPage"
import EquipmentPage from "./main/EquipmentPage"

import HomePage from "./main/HomePage"
import OperatingRulesPage from "./main/OperatingRulesPage"

export const routes = {
  path: "",
  element: <View />,
  children: [
    { path: "", element: <HomePage /> },
    { path: "equipment", element: <EquipmentPage /> },
    { path: "operating-rules", element: <OperatingRulesPage /> },
    { path: "contact", element: <ContactPage /> },
    { path: "cookies", element: <PersonalDataProtectionPage /> },
  ],
}
