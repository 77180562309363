import { isDarkTheme, toggleTheme as fdToggleTheme } from "@fastdo-react"
import { createSlice } from "@reduxjs/toolkit"

export interface InitialState {
  darkTheme: boolean
}

const initialState: InitialState = {
  darkTheme: isDarkTheme(),
}

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkTheme = !state.darkTheme
      fdToggleTheme()
    },
  },
})

export default themeSlice.reducer
export const { toggleTheme } = themeSlice.actions
