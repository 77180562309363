import { Outlet } from "react-router-dom"
import NavbarOld, { NavItem } from "@fastdo-react/components/nav-bar/NavbarOld"
import { useTranslation } from "react-i18next"
import LocalizedLink from "components/router/LocalizedLink"
import { getLang, setLangAndGetUrl, supportedLangs } from "i18n/config"
import { useAppDispatch, useAppSelector } from "common/storeHooks"
import { toggleTheme } from "common/slices/themeSlice"
import { CookieConsent, HrSpacer, Spacer, redirect } from "@fastdo-react"
import Footer from "components/Footer"
import { setAllAccepted } from "common/slices/cookiesSlice"

export default function View() {
  const themeSlice = useAppSelector((state) => state.theme)
  const cookiesSlice = useAppSelector((state) => state.cookies)
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const navItems: NavItem[] = [
    { to: "/equipment", text: t("equipment_title") },
    { to: "/operating-rules", text: t("operating_rules_title") },
    { to: "/contact", text: t("contact_title") },
  ]

  return (
    <>
      <NavbarOld
        LinkElement={LocalizedLink}
        items={navItems}
        homeItem={{ to: "/", text: "Kemp na Břečkárně" }}
        homeLogo={process.env.PUBLIC_URL + "/assets/logos/logo.svg"}
        homeLogoLink="/"
        homeLogoTop={1}
        homeLogoLeft={1}
        navTopDefault={-50}
        language={getLang()}
        languages={supportedLangs}
        onLanguageChange={(value: string) => {
          var url = setLangAndGetUrl(value)
          redirect(url)
        }}
        themeSwitcher={true}
        darkThemeSelected={themeSlice.darkTheme}
        onThemeChange={() => dispatch(toggleTheme())}
      />

      <Outlet />

      <HrSpacer />
      <Footer />

      {cookiesSlice.set === false && (
        <CookieConsent
          onAcceptAll={() => dispatch(setAllAccepted())}
          settingsButtonLabel=""
          title={t("cookie_popup_title")!}
          text={t("cookie_popup_text")!}
        >
          <Spacer size={20} />
          <LocalizedLink
            to="/cookies"
            style={{
              color: "var(--fastdo-dark-color)",
              fontWeight: "800",
              marginLeft: "22px",
              textDecoration: "underline",
            }}
          >
            {t("cookie_popup_learn_more")}
          </LocalizedLink>
        </CookieConsent>
      )}
    </>
  )
}

// "cookie_popup_title": "Používáme cookies",
// "cookie_popup_text": "Soubory cookie používáme k analýze údajů o našich návštěvnících, ke zlepšení našich webových stránek, zobrazení personalizovaného obsahu a k tomu, abychom vám poskytli skvělý zážitek z webu.",
// "cookie_popup_accept_all": "Příjmout vše",
// "cookie_popup_learn_more": "Zjistit více"
