import styles from "./ContactPage.module.css"
import { AiFillPhone, AiFillMail } from "react-icons/ai"
import { MdLocationPin } from "react-icons/md"
import Card from "@fastdo-react/components/cards/Card"
import { useTranslation } from "react-i18next"
import Spacer from "@fastdo-react/components/general/Spacer"
import { HomeLayout } from "@fastdo-react"
import Head from "react-helmet"

export default function ContactPage() {
  const { t } = useTranslation()

  // const [request, setRequest] = useState<ContactForms_Send_SendRequest>({
  //   name: "",
  //   email: "",
  //   phoneNumber: "",
  //   message: "",
  // })
  // const [loadingSubmit, setLoadingSubmit] = useState(false)

  // const submit = async () => {
  //   setLoadingSubmit(true)
  //   try {
  //     await agent.ContactForms.send(request)
  //     toast.success("Úspěšně odesláno")
  //   } catch {}
  //   setLoadingSubmit(false)
  // }

  return (
    <HomeLayout
      title={t("contact_title")!}
      headProps={{
        HeadHelement: Head,
        title: `${t("contact_title")} - Na Břečkárně`,
        link: "https://nabreckarne.cz/contact",
        imageCard: "https://nabreckarne.cz/assets/logos/logo.png",
      }}
      style={{ paddingBottom: "0" }}
      scroolToTop
      downOutsideElement={
        <div className={styles.googleMapsContainer}>
          <iframe
            title="googleMaps"
            src="https://maps.google.com/maps?q=Na%20b%C5%99e%C4%8Dk%C3%A1rn%C4%9B&t=&z=13&ie=UTF8&iwloc=&output=embed"
            style={{ width: "100%", height: "500px", border: "0" }}
            aria-hidden="false"
          />
        </div>
      }
    >
      <Card>
        <div className={styles.itemRow}>
          <AiFillPhone className={styles.icon} />
          <a href="tel:+420 723 075 027" className="fastdo-body1-link-1">
            <strong>+420 723 075 027</strong>
          </a>
        </div>
        <Spacer size={16} />
        <div className={styles.itemRow}>
          <AiFillMail className={styles.icon} />
          <a href="mailto:nabreckarne@seznam.cz" className="fastdo-body1-link-1">
            <strong>nabreckarne@seznam.cz</strong>
          </a>
        </div>
        <Spacer size={16} />
        <div className={styles.itemRow}>
          <MdLocationPin className={styles.icon} />
          <p>
            <strong>Rataje nad Sázavou 18, 285 07</strong>
          </p>
        </div>
        <Spacer size={16} />
        <div className={styles.itemRow}>
          <div className={styles.textIcon}>IČO</div>
          <p>
            <strong>14364441</strong>
          </p>
        </div>
        <Spacer size={16} />
        <div className={styles.itemRow}>
          <div className={styles.textIcon}>DIČ</div>
          <p>
            <strong>CZ9501300039</strong>
          </p>
        </div>
      </Card>
      <Spacer />

      {/* <h1>{t("contact_write_us_title")}</h1>
      <FormLayout submitLabel="Odeslat" loadingSubmit={loadingSubmit} onSubmit={submit}>
        <TextField
          id="name"
          label={t("name")!}
          value={request.name}
          onChange={(value) => setRequest({ ...request, name: value })}
        />
        <Spacer />

        <TextField
          id="email"
          label={t("email")!}
          value={request.email}
          onChange={(value) => setRequest({ ...request, email: value })}
        />
        <Spacer />

        <TextField
          id="tel"
          label={t("telephone")!}
          value={request.phoneNumber}
          onChange={(value) => setRequest({ ...request, phoneNumber: value })}
        />
        <Spacer />

        <TextArea
          label={t("message")!}
          value={request.message ?? ""}
          onChange={(value) => setRequest({ ...request, message: value })}
        />
        <Spacer />

        <div style={{ margin: "var(--fastdo-text-field-label-margin)" }}>
          <p className="fastdo-label">{t("contact_write_us_personal_data_protection_message")}</p>
          <Link to="/cookies" className="fastdo-label-link-1">
            {t("personal_data_protection_link")}
          </Link>
        </div>
      </FormLayout> */}
      <Spacer size={20} />
    </HomeLayout>
  )
}
