import { configureStore } from "@reduxjs/toolkit"
import themeSlice from "./slices/themeSlice"
import cookiesSlice from "./slices/cookiesSlice"

const store = configureStore({
  reducer: {
    theme: themeSlice,
    cookies: cookiesSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
