import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  analyticalCookiesAcceptedKey,
  getAccept,
  getAllAccepted,
  getCookiesSet,
  setAllAccepted as fdSetAllAccepted,
  setCookiesSet,
} from "@fastdo-react"

export interface InitialState {
  showSettings: boolean
  set: boolean
  allAccepted: boolean
  analyticalAccepted: boolean
}

const initialState: InitialState = {
  showSettings: false,
  set: getCookiesSet(),
  allAccepted: getAllAccepted(),
  analyticalAccepted: getAccept(analyticalCookiesAcceptedKey),
}

export const cookiesSlice = createSlice({
  name: "cookies",
  initialState: initialState,
  reducers: {
    setShowSettings: (state, action: PayloadAction<boolean>) => {
      state.showSettings = action.payload
    },
    setSet: (state) => {
      state.set = true
      setCookiesSet()
    },
    setAllAccepted: (state) => {
      state.allAccepted = true
      fdSetAllAccepted()
      state.set = true
      state.showSettings = false
    },
  },
})

export default cookiesSlice.reducer
export const { setShowSettings, setSet, setAllAccepted } = cookiesSlice.actions
