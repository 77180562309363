import styles from "./EquipmentPage.module.css"
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@mui/material"
import { GiWaterBottle, GiCampfire, GiSoccerBall } from "react-icons/gi"
import { FaRestroom, FaShower, FaDog, FaParking } from "react-icons/fa"
import { IoMdRestaurant } from "react-icons/io"
import { BsFillCreditCardFill } from "react-icons/bs"
import { TbToolsKitchen } from "react-icons/tb"
import { AiOutlineArrowUp, AiOutlineWifi } from "react-icons/ai"
import { GiWashingMachine } from "react-icons/gi"
import { useTranslation } from "react-i18next"
import Spacer from "@fastdo-react/components/general/Spacer"
import { HomeLayout } from "@fastdo-react"
import Head from "react-helmet"

export default function EquipmentPage() {
  const { t } = useTranslation()

  const accordionElevation = 6
  const paperAccordionSummarySx = { cursor: "default !important" }

  const items = [
    {
      title: t("equipment_drinking_water"),
      detail: t("equipment_drinking_water_detail"),
      icon: GiWaterBottle,
    },
    {
      title: t("equipment_toilets"),
      detail: t("equipment_toilets_detail"),
      icon: FaRestroom,
    },
    {
      title: t("equipment_warm_shower"),
      detail: t("equipment_warm_shower_detail"),
      icon: FaShower,
    },
    {
      title: t("equipment_playground"),
      detail: t("equipment_playground_detail"),
      icon: GiSoccerBall,
    },
    {
      title: t("equipment_animals"),
      detail: t("equipment_animals_detail"),
      icon: FaDog,
    },
    {
      title: t("equipment_parking"),
      detail: t("equipment_parking_detail"),
      icon: FaParking,
    },
    {
      title: t("equipment_fireplace"),
      detail: t("equipment_fireplace_detail"),
      icon: GiCampfire,
    },
    {
      title: t("equipment_refreshment"),
      detail: t("equipment_refreshment_detail"),
      icon: IoMdRestaurant,
    },
    {
      title: t("equipment_wifi"),
      detail: t("equipment_wifi_detail"),
      icon: AiOutlineWifi,
    },
  ]

  const itemsMiss = [
    {
      title: t("equipment_miss_kitchenette"),
      icon: TbToolsKitchen,
    },
    {
      title: t("equipment_washing_machine"),
      icon: GiWashingMachine,
    },
    {
      title: t("equipment_payment_cards"),
      icon: BsFillCreditCardFill,
    },
  ]

  return (
    <HomeLayout
      title={t("equipment_title")!}
      headProps={{
        HeadHelement: Head,
        title: `${t("equipment_title")} - Na Břečkárně`,
        link: "https://nabreckarne.cz/equipment",
        imageCard: "https://nabreckarne.cz/assets/logos/logo.png",
      }}
      scroolToTop
    >
      <>
        {items.map((item, index) => (
          <Accordion
            elevation={accordionElevation}
            key={index}
            style={{ background: "var(--fastdo-primary-background)" }}
          >
            <AccordionSummary expandIcon={<AiOutlineArrowUp style={{ color: "var(--fastdo-dark-color)" }} />}>
              <div className={styles.accordionHeaderDiv}>
                <item.icon className={styles.equipmentIcon} />
                <Typography>
                  <strong>{item.title}</strong>
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.detail}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        <Spacer />

        <h1>{t("equipment_miss_title")}</h1>
        {itemsMiss.map((item, index) => (
          <Paper key={index} elevation={accordionElevation} style={{ background: "var(--fastdo-primary-background)" }}>
            <AccordionSummary sx={paperAccordionSummarySx}>
              <div className={styles.accordionHeaderDiv}>
                <item.icon className={styles.equipmentIcon} />
                <Typography>
                  <strong>{item.title}</strong>
                </Typography>
              </div>
            </AccordionSummary>
          </Paper>
        ))}
      </>
    </HomeLayout>
  )
}
