import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Spacer from "@fastdo-react/components/general/Spacer"
import styles from "./Footer.module.css"

export default function Footer() {
  const { t } = useTranslation()

  return (
    <footer className={styles.component}>
      <address>
        Tábořiště Na Břečkárně
        <br />
        Ondřej Rozehnal
        <br />
        IČO: 14364441
        <br />
        Rataje nad Sázavou 18, 285 07
      </address>
      <Spacer />

      <Link to="/cookies" style={{ fontSize: "var(--fastdo-body2-font-size)" }}>
        {t("personal_data_protection_link")}
      </Link>
      <Spacer />

      {/* <p>
        {t("created_by")}: <a href="https://petrjilek.eu/">petrjilek.eu</a>
      </p>
      <Spacer /> */}

      <p>&copy; Copyright 2023</p>
    </footer>
  )
}
