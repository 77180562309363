import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { getUrlGetParam } from "@fastdo-react"

export const defaultLang = "cz"
export const supportedLangs = ["cz", "en"]

export const getLang = () => {
  const lang = getUrlGetParam("lang")
  if (lang && supportedLangs.includes(lang)) return lang
  return defaultLang
}

export const getUrlParamFormat = () => {
  var lang = getLang()
  if (lang === defaultLang) return ""
  return `?lang=${lang}`
}

export const setLangAndGetUrl = (lang: string) => {
  lang = supportedLangs.includes(lang) ? lang : defaultLang
  var url = window.location.pathname
  if (lang === defaultLang) return url
  return `${url}?lang=${lang}`
}

i18n.use(initReactI18next).init({
  fallbackLng: defaultLang,
  lng: getLang(),
  resources: {
    cz: {
      translations: require("./locales/cz.json"),
    },
    en: {
      translations: require("./locales/en.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
})

i18n.languages = supportedLangs

export default i18n

// https://www.freecodecamp.org/news/how-to-add-localization-to-your-react-app/
