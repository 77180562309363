import { useRef } from "react"
import PrimaryHelmet from "@fastdo-react/components/yolo/PrimaryHelmet"
import Head from "react-helmet"
import HomeTile from "components/pages/HomeTile"
import GalleryTile from "components/pages/GalleryTile"
import { HomeLayout, HrSpacer } from "@fastdo-react"
import { useTranslation } from "react-i18next"

export default function HomePage() {
  const { t } = useTranslation()

  const galleryRef = useRef<null | HTMLDivElement>(null)
  const executeScrollToGallery = () => galleryRef?.current?.scrollIntoView({ behavior: "smooth" })

  return (
    <div>
      <PrimaryHelmet
        HeadHelement={Head}
        title={`Na Břečkárně`}
        link="https://nabreckarne.cz"
        imageCard="https://nabreckarne.cz/assets/logos/logo.png"
      />
      <HomeTile onGalleryClick={executeScrollToGallery} />
      <HrSpacer />

      <div ref={galleryRef}></div>
      <HomeLayout title={t("gallery_title")!} style={{ paddingTop: "0" }}>
        <GalleryTile />
      </HomeLayout>
    </div>
  )
}
