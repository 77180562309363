import { HomeLayout } from "@fastdo-react"
import { useTranslation } from "react-i18next"
import Head from "react-helmet"

export default function OperatingRulesPage() {
  const { t } = useTranslation()

  return (
    <HomeLayout
      title={t("operating_rules_title")!}
      headProps={{
        HeadHelement: Head,
        title: `${t("operating_rules_title")} - Na Břečkárně`,
        link: "https://nabreckarne.cz/operating-rules",
        imageCard: "https://nabreckarne.cz/assets/logos/logo.png",
      }}
    >
      <ol>
        {Array.from(Array(27).keys()).map((index) => (
          <li key={index} style={{ color: "var(--fastdo-dark-color)" }}>
            {t(`operating_rule_${index + 1}`)}
          </li>
        ))}
      </ol>
    </HomeLayout>
  )
}
