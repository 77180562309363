import { useTranslation } from "react-i18next"
import styles from "./GalleryTile.module.css"

export default function GalleryTile() {
  const { t } = useTranslation()

  const images = [
    { link: "/assets/images/cesta.webp", title: t("gallery_road") },
    { link: "/assets/images/karavany.webp", title: t("gallery_caravans") },
    { link: "/assets/images/kemp.webp", title: t("gallery_camp") },
    { link: "/assets/images/louka.webp", title: t("gallery_meadow") },
    { link: "/assets/images/stoly.webp", title: t("gallery_tables") },
    { link: "/assets/images/zapad_slunce.webp", title: t("gallery_sunset") },
  ]

  return (
    <div className={styles.component}>
      {images.map((item, index) => {
        return (
          <div key={index} className={styles.imgContainer}>
            <img src={item.link} alt={item.title} title={item.title} loading="lazy" />
          </div>
        )
      })}
    </div>
  )
}
