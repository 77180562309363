import { Button, Spacer } from "@fastdo-react"
import styles from "./HomeTile.module.css"
import { useTranslation } from "react-i18next"
import { GiCampingTent, GiCampfire } from "react-icons/gi"
import FireAnimation from "components/FireAnimation"

export interface Props {
  onGalleryClick: () => void
}

export default function HomeTile({ onGalleryClick }: Props) {
  const { t } = useTranslation()

  return (
    <div className={styles.homePageMainOutterDiv}>
      <div className={styles.homePageMainDiv}>
        <div className={styles.headerDiv}>
          <div>
            <h1>{t("web_title")}</h1>
            <p>{t("about_1")}</p>
            <p>{t("about_2")}</p>
            <Spacer />
            <div className={styles.toGalleryDiv} style={{ textAlign: "center" }}>
              <Button label={t("gallery_title")} onClick={onGalleryClick} />
            </div>
          </div>
        </div>

        <div className={styles.iconsDiv}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "relative" }}>
              <GiCampfire className={styles.fireIcon} />
            </div>
            <FireAnimation />
          </div>
          <GiCampingTent className={styles.tentIcon} />
        </div>
      </div>
    </div>
  )
}
