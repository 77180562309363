import ReactDOM from "react-dom/client"
import App from "App"
import "./i18n/config"
import "@fastdo-react/styles/index.css"
import "@fastdo-react/styles/palettes/kemp-nb.css"
import "styles/fire-animation.css"
import { Provider } from "react-redux"
import store from "common/store"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
