import { NotFoundView as FastDoNotFoundView } from "@fastdo-react"
import LocalizedLink from "components/router/LocalizedLink"

export default function NotFoundView() {
  return (
    <FastDoNotFoundView
      header="Stránka nenalezena"
      text="Stránka, kterou hledáte, mohla být odstraněna, pokud se změnil její název, nebo je dočasně nedostupná."
      actionComponent={
        <LocalizedLink to="/" className="fastdo-button fastdo-button-default">
          Zpět na web
        </LocalizedLink>
      }
    />
  )
}
